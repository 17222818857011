import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { CASE_SCOPES } from '@/api/auth/permissions';
import { isAnyPhaseWithExternalDependencyInProgress } from '@/api/predicates/casePhase';
import queryClient from '@/api/queryClient';

export type Response = Awaited<ReturnType<typeof caseApi.getCaseByCaseId>>;

export type GetCaseQueryParams = {
  caseId?: string | null;
};

export function getCaseQuery(
  params?: GetCaseQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [CASE_SCOPES.getCase, params?.caseId];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.caseId) throw new TypeError('No caseId provided');
      return caseApi.getCaseByCaseId(params.caseId, {
        ...opts,
        signal,
      });
    },
    refetchInterval: (query) => {
      const phases = query.state.data?.phases;
      if (isAnyPhaseWithExternalDependencyInProgress(phases)) {
        return ms('30s');
      }
      return false;
    },
    staleTime: ms('1d'),
    gcTime: ms('5m'),
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });
}

export async function fetchCaseQuery(
  params?: GetCaseQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCaseQuery(params, opts));
}

export function useCase(
  params?: GetCaseQueryParams,
  options?: Omit<ReturnType<typeof getCaseQuery>, 'queryKey'>,
) {
  const query = getCaseQuery(params);
  return useQuery({
    ...query,
    staleTime: 0,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
