/**
 * This module contains predicate functions related to case phases.
 * Predicates are used to determine specific properties or conditions of case phases.
 */
import { caseApi } from '@/api';

/**
 * Determines whether a phase is in progress or completed.
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is in progress or completed, false otherwise.
 */
export function isPhaseInProgressOrCompleted(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return phase?.phaseStateCode
    ? Object.values([
        caseApi.CasePhaseState.Done,
        caseApi.CasePhaseState.Progress,
        caseApi.CasePhaseState.Restarted,
        caseApi.CasePhaseState.Pending,
        caseApi.CasePhaseState.Rejected,
      ])
        .map((value) => value.toLowerCase())
        .includes(phase.phaseStateCode.toLowerCase())
    : false;
}

/**
 * Determines whether a phase is in progress.
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is in progress, false otherwise.
 */
export function isPhaseInProgress(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return phase?.phaseStateCode
    ? [caseApi.CasePhaseState.Progress, caseApi.CasePhaseState.Pending]
        .map((value) => value.toLowerCase())
        .includes(phase.phaseStateCode.toLowerCase())
    : false;
}

/**
 * Determines whether a phase is done.
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is done, false otherwise.
 */
export function isPhaseDone(phase?: caseApi.CasePhaseModel | null): boolean {
  return (
    phase?.phaseStateCode?.toLowerCase() ===
    caseApi.CasePhaseState.Done.toLowerCase()
  );
}

export function isPhaseRejected(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return (
    phase?.phaseStateCode?.toLowerCase() ===
    caseApi.CasePhaseState.Rejected.toLowerCase()
  );
}

/**
 * Determines whether a phase is done, rejected, or cancelled.
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is done, rejected, or cancelled, false otherwise.
 */
export function isPhaseDoneOrRejected(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return phase?.phaseStateCode
    ? [
        caseApi.CasePhaseState.Done,
        caseApi.CasePhaseState.Rejected,
        caseApi.CasePhaseState.Cancelled,
      ]
        .map((value) => value.toLowerCase())
        .includes(phase.phaseStateCode.toLowerCase())
    : false;
}

/**
 * Determines whether a phase should be excluded.
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase should be excluded, false otherwise.
 */
export function isPhaseToBeExcluded(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return phase?.phaseStateCode
    ? [caseApi.CasePhaseState.NotApplicable]
        .map((value) => value.toLowerCase())
        .includes(phase.phaseStateCode.toLowerCase())
    : false;
}

/**
 * Determines whether a phase is a calculation phase (Calculation).
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is a calculation phase, false otherwise.
 */
export function isCalculcationPhase(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return (
    phase?.casePhaseCode?.toLowerCase() ===
    caseApi.CasePhaseCode.Calculation.toLowerCase()
  );
}

/**
 * Determines whether a phase is a contract generation phase (AgreementGeneration).
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is a contract generation phase, false otherwise.
 */
export function isContractGenerationPhase(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return (
    phase?.casePhaseCode?.toLowerCase() ===
    caseApi.CasePhaseCode.AgreementGeneration.toLowerCase()
  );
}

/**
 * Determines whether a phase is a contract agreement phase (AgreementConfirmation).
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is a contract agreement phase, false otherwise.
 */
export function isContractAgreementPhase(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return (
    phase?.casePhaseCode?.toLowerCase() ===
    caseApi.CasePhaseCode.AgreementConfirmation.toLowerCase()
  );
}

/**
 * Determines whether a phase is an individual price setting phase (IndiviualPricing).
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is an individual price setting phase, false otherwise.
 */
export function isIndividualPriceSettingPhase(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return (
    phase?.casePhaseCode?.toLowerCase() ===
    caseApi.CasePhaseCode.IndividualPricing.toLowerCase()
  );
}

/**
 * Determines whether a phase is an execution phase (Execution).
 * @param {caseApi.CasePhaseModel | null} phase - The phase to check.
 * @returns {boolean} - True if the phase is an execution phase, false otherwise.
 */
export function isExecutionPhase(
  phase?: caseApi.CasePhaseModel | null,
): boolean {
  return (
    phase?.casePhaseCode?.toLowerCase() ===
    caseApi.CasePhaseCode.Execution.toLowerCase()
  );
}
/**
 * Checks if any phase with an external dependency is currently in progress.
 *
 * @param {caseApi.CasePhaseModel[]} phases - An array of case phases.
 * @returns {boolean} - A boolean value indicating if any phase with an external dependency is in progress.
 */
export function isAnyPhaseWithExternalDependencyInProgress(
  phases?: caseApi.CasePhaseModel[] | null,
): boolean {
  return [
    // dependent on document being uploaded manually in Workzone
    isPhaseInProgress(phases?.find(isContractAgreementPhase)),
    // dependent on execution phase being in executed through a job to LAS
    isPhaseInProgress(phases?.find(isExecutionPhase)),
  ].some((x) => x);
}
