import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { LOAN_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = loanApi.PartialRedemptionTermDateResponse; // Awaited<ReturnType<typeof method>>;

export type GetPartialRedemptionDatesQueryParams = {
  calculationPath?: string | null;
};

export function getPartialRedemptionDatesQuery(
  params?: GetPartialRedemptionDatesQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    LOAN_SCOPES.getPartialRedemptionDates,
    params?.calculationPath,
  ];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.calculationPath) {
        throw new TypeError('calculationPath is required');
      }
      return loanApi.getPartialredemptiondateByCalculationPath(
        params.calculationPath,
        {},
        { ...opts, signal },
      ) as Promise<Response>;
    },
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
    staleTime: ms('12h'),
    gcTime: ms('1d'),
  });
}

export async function fetchPartialRedemptionDatesQuery(
  params?: GetPartialRedemptionDatesQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getPartialRedemptionDatesQuery(params, opts));
}

export function usePartialRedemptionDates(
  params?: GetPartialRedemptionDatesQueryParams,
  options?: Omit<ReturnType<typeof getPartialRedemptionDatesQuery>, 'queryKey'>,
) {
  const canGetPartialRedemptionDates = useHasPermission(
    LOAN_SCOPES.getPartialRedemptionDates,
  );
  return useQuery({
    ...getPartialRedemptionDatesQuery(params),
    meta: {
      showErrorToast: true,
    },
    ...options,
    enabled:
      !!params?.calculationPath &&
      options?.enabled &&
      canGetPartialRedemptionDates,
  });
}
