import { useMemo } from 'react';
import useLocalStorage from '@kk/shared/hooks/useLocalStorage';
import { AutocompleteSearchResult, SearchTypes } from './structureData';

const MAX_ITEMS_IN_LOCAL_STORAGE = 32;
const buster = __BUILD_ID__ || 'dev';

export function useSavedSearch() {
  const [savedSearch = [], setSavedSearch] = useLocalStorage<
    Array<AutocompleteSearchResult>
  >(`recent-searches-${buster}`, []);

  function addSavedSearch(result: AutocompleteSearchResult) {
    if (savedSearch.some((item) => item.key === result.key)) {
      const newSavedSearch = savedSearch.filter(
        (item) => item.key !== result.key,
      );

      newSavedSearch.unshift(result);
      setSavedSearch(newSavedSearch);
    } else {
      const newSavedSearch = [...savedSearch];
      if (savedSearch.length >= MAX_ITEMS_IN_LOCAL_STORAGE) {
        newSavedSearch.pop();
      }
      setSavedSearch([result, ...newSavedSearch]);
    }
  }

  function removeSavedSearch(key: string, callback: VoidFunction) {
    const newSavedSearch = savedSearch.filter((item) => item.key !== key);
    if (newSavedSearch.length === 0) {
      callback();
    }
    setSavedSearch(newSavedSearch);
  }

  const grouped = useMemo(() => {
    const groupedSavedSearch = savedSearch.reduce<{
      companies: AutocompleteSearchResult[];
      loans: AutocompleteSearchResult[];
    }>(
      (acc, item) => {
        if (item.searchType === 'entity') {
          acc[item.group]?.push(item);
        }
        return acc;
      },
      {
        companies: [],
        loans: [],
      },
    );

    let groupedSavedSearchArray: AutocompleteSearchResult[] = [];
    Object.keys(groupedSavedSearch).forEach((key) => {
      if (groupedSavedSearch[key]?.length) {
        groupedSavedSearchArray.push({
          name: key,
          searchType: SearchTypes.Group,
          count: groupedSavedSearch[key]?.length,
          key,
        });
        groupedSavedSearchArray = groupedSavedSearchArray.concat(
          groupedSavedSearch[key]?.slice(0, 5),
        );
      }
    });

    return groupedSavedSearchArray;
  }, [savedSearch]);

  return { savedSearch: grouped, addSavedSearch, removeSavedSearch };
}
