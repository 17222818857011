import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { CASE_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<typeof caseApi.getCaseByCaseIdAssignees>
>;

export type GetCaseAssigneesQueryParams = {
  caseId?: string | null;
};

export function getCaseAssigneesQuery(
  params?: GetCaseAssigneesQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [CASE_SCOPES.getPhaseAssignees, params?.caseId];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.caseId) throw new TypeError('No caseId provided');
      return caseApi.getCaseByCaseIdAssignees(params.caseId, {
        ...opts,
        signal,
      });
    },
    staleTime: ms('1d'),
    gcTime: ms('10m'),
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
}

export async function fetchCaseAssigneesQuery(
  params: GetCaseAssigneesQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCaseAssigneesQuery(params, opts));
}

export function useCaseAssignees(
  params: GetCaseAssigneesQueryParams,
  options?: Omit<ReturnType<typeof getCaseAssigneesQuery>, 'queryKey'>,
) {
  const query = getCaseAssigneesQuery(params);
  return useQuery({
    ...query,
    staleTime: ms('10s'),
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
