import { queryOptions, useQuery } from '@tanstack/react-query';
import compareDesc from 'date-fns/compareDesc';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { CASE_SCOPES } from '@/api/auth/permissions';
import { getCaseQuery } from '@/api/hooks/case/useCase';
import { isAnyPhaseWithExternalDependencyInProgress } from '@/api/predicates/casePhase';
import { isCaseClosed } from '@/api/predicates/loanCase';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<typeof caseApi.getContractByContractNumberCases>
>;

export type GetContractCasesQueryParams = {
  contractId?: string | null;
  active?: boolean;
};

export function getContractCasesQuery(
  params?: GetContractCasesQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    CASE_SCOPES.getContractCases,
    params?.contractId,
    params?.active,
  ];

  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      if (!params?.contractId) throw new TypeError('No contractId provided');
      return caseApi
        .getContractByContractNumberCases(
          params.contractId,
          {
            active: params.active ?? false,
          },
          {
            ...opts,
            signal,
          },
        )
        .then((data) => addCasesToCache(data, params));
    },
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
    staleTime: ms('1d'),
    gcTime: ms('10m'),
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchInterval: (query) => {
      if (
        params?.active === true &&
        query.state.data?.some((loanCase) =>
          isAnyPhaseWithExternalDependencyInProgress(loanCase.phases),
        )
      ) {
        return ms('30s');
      }
      return false;
    },
  });
}

/**
 * Adds cases to cache based on the provided parameters.
 *
 * @param {caseApi.CaseModel[]} data - The data to add to cache.
 * @param {GetContractCasesQueryParams} params - The query parameters used to fetch the data.
 * @returns {caseApi.CaseModel[]} - The input data array.
 */
function addCasesToCache(
  data: Response,
  params: GetContractCasesQueryParams,
): Response {
  try {
    if (!params.active) {
      // if requested all cases, add active cases to cache
      const activeCases = data.filter(
        (loanCase) => isCaseClosed(loanCase) === false,
      );
      if (activeCases.length > 0) {
        queryClient.setQueryData(
          getContractCasesQuery({ contractId: params.contractId, active: true })
            .queryKey,
          activeCases,
        );
      }
    }
    // add all cases to cache
    data.filter(Boolean).forEach((contractCase) => {
      queryClient.setQueryData(
        getCaseQuery({ caseId: contractCase.caseId }).queryKey,
        contractCase,
      );
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  } finally {
    return data;
  }
}

export async function fetchContractCasesQuery(
  params: GetContractCasesQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getContractCasesQuery(params, opts));
}

export function useContractCases(
  params: GetContractCasesQueryParams,
  options?: Omit<ReturnType<typeof getContractCasesQuery>, 'queryKey'>,
) {
  const canGetContractCases = useHasPermission(CASE_SCOPES.getContractCases);
  return useQuery({
    ...getContractCasesQuery(params),
    staleTime: 0,
    ...options,
    enabled: canGetContractCases,
    select: (data: Response) => {
      const selectedData = data
        .sort((a, b) =>
          compareDesc(new Date(a.createDate ?? 0), new Date(b.createDate ?? 0)),
        )
        .filter((loanCase) => {
          if (params.active) {
            return isCaseClosed(loanCase) === false;
          }
          return isCaseClosed(loanCase);
        });
      if (typeof options?.select === 'function')
        return options.select(selectedData);
      return selectedData;
    },
  });
}
